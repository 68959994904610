// Dates
const ONE_YEAR_IN_MONTHS = 12;
const MAX_DAYS_IN_YEAR = 366;

// Finance
const DEFAULT_FINANCE_PERIOD_IN_YEARS = 3;
const DEFAULT_FINANCE_PERIOD_IN_MONTHS = DEFAULT_FINANCE_PERIOD_IN_YEARS * ONE_YEAR_IN_MONTHS;
const DEFAULT_TAX_RATE = 20;
const MIN_LEASE_TERM = 2;
const MAX_LEASE_TERM = 6;

// Mileage
const DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE = 8000;
const DEFAULT_BUSINESS_FLEET_MANAGER_MILEAGE = 20000;
const MIN_MILEAGE = 1;
const MAX_MILEAGE = 100000;

// Fuel
const DEFAULT_ELECTRIC_FUEL_PRICE = 22.36;
const DEFAULT_PETROL_FUEL_PRICE = 142.96;
const DEFAULT_DIESEL_FUEL_PRICE = 145.13;
const LITRES_PER_GALLON = 4.54609;
const KWH_PER_GALLON_ICE_FUEL = 33.7;
const MILES_TO_KILOMETERS_CONVERSION_RATE = 1.60934;

// Other
const DEFAULT_MONTHLY_CLEAN_AIR_ZONE_ENTRIES = 5;

export {
  ONE_YEAR_IN_MONTHS,
  DEFAULT_FINANCE_PERIOD_IN_YEARS,
  DEFAULT_FINANCE_PERIOD_IN_MONTHS,
  DEFAULT_PRIVATE_AND_COMPANY_CAR_MILEAGE,
  DEFAULT_BUSINESS_FLEET_MANAGER_MILEAGE,
  DEFAULT_ELECTRIC_FUEL_PRICE,
  DEFAULT_PETROL_FUEL_PRICE,
  DEFAULT_DIESEL_FUEL_PRICE,
  LITRES_PER_GALLON,
  KWH_PER_GALLON_ICE_FUEL,
  MILES_TO_KILOMETERS_CONVERSION_RATE,
  DEFAULT_MONTHLY_CLEAN_AIR_ZONE_ENTRIES,
  DEFAULT_TAX_RATE,
  MIN_MILEAGE,
  MAX_MILEAGE,
  MIN_LEASE_TERM,
  MAX_LEASE_TERM,
  MAX_DAYS_IN_YEAR,
};
