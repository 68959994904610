import motability from 'shared/brandConfigs/motability';
import audi from 'shared/brandConfigs/audi';
import volkswagen from 'shared/brandConfigs/volkswagen';
import volvo from 'shared/brandConfigs/volvo';
import waylands from 'shared/brandConfigs/waylands';
import ford from 'shared/brandConfigs/ford';
import { Product } from 'shared/constants';
import { MenuLink } from 'features/NavMenu/NavMenu';

export interface BaseConfig {
  [Product.COMPARATOR]: {
    components?: { displayShowcaseHeader: boolean };
    features: {
      shopLinkCTA: { isShopCTAShown: boolean; makeShopLink: () => void };
    };
  };
  [Product.DETAILED_COMPARATOR]: {};
  [Product.USED_COMPARATOR]: {};
  [Product.EV_TREE]: {};
  [Product.ALL]: {
    navMenuLinks: MenuLink[];
    confirmVehicle: { hideIcon: boolean };
    content?: { footer: { line1: { here: string } } };
    showNewLegalUpdates: boolean;
  };
}

const defaultConfig: BaseConfig = {
  [Product.COMPARATOR]: {
    components: { displayShowcaseHeader: false },
    features: {
      shopLinkCTA: { isShopCTAShown: false, makeShopLink: () => '' },
    },
  },
  [Product.DETAILED_COMPARATOR]: {
    features: {
      currentMileage: { shouldShowCurrentMileageInput: true },
      shopLinkCTA: { isShopCTAShown: false, makeShopLink: () => '' },
    },
  },
  [Product.USED_COMPARATOR]: {
    earliestRegistrationYearPermitted: 12,
    features: {
      currentMileage: { shouldShowCurrentMileageInput: true },
      vehicleSummary: { shouldHideOnTheRoadPrice: true },
      pageHeader: { shouldHideNavMenuOnPage: true },
    },
    filters: { useManufacturersFilters: [{ withCurrentModelsOnly: false }] },
  },
  [Product.EV_TREE]: {},
  [Product.ALL]: {
    navMenuLinks: [
      {
        title: 'Running costs tools',
        links: [
          { title: 'Home', links: [], url: '/volvo' },
          {
            title: 'Is an electric car right for you?',
            links: [],
            url: '/volvo/ev-tree?id=200fa256-056c-474a-ad70-eb94ea590dd1',
          },
          {
            title: 'Simple comparison tool',
            links: [],
            url: '/volvo/comparator',
          },
          {
            title: 'Detailed comparison tool',
            links: [],
            url: '/volvo/detailed-comparator',
          },
        ],
        url: '',
      },
    ],
    confirmVehicle: {
      hideIcon: false,
    },
    showNewLegalUpdates: false,
  },
};

export const configs = { defaultConfig, audi, ford, volvo, motability, waylands, volkswagen };
